import React from 'react';
import { Box, Stack, styled } from '@mui/material';
import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwRichContent } from '@lib/components/SwRichContent';
import { SwSharingLinksSection } from '@lib/components/SwSharingLinksSection';
import { SwTableOfContents } from '@lib/components/SwTableOfContents';
import { grey3, grey5, grey7 } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SUB_NAV_HEIGHT, SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwLayout from '../components/v1/SwLayout';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const Header = styled('div')`
    display: grid;
    grid-template-columns: minmax(300px, auto) 55%;
    gap: 48px;
    margin-block: 32px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        grid-template-columns: 1fr;
    }
`;

const HeaderContent = styled(SwFlexBox)`
    padding-block-start: 48px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding-block-start: 16px;
    }
`;

const Paper = styled('div')`
    width: 100%;
    background-color: ${grey7};
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const MainContent = styled(SwFlexBox)`
    background: white;
    border-radius: 8px;
    border: 1px solid ${grey5};
    padding: 32px;
    overflow: hidden;
    grid-area: main-content;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        padding: 20px;
    }
`;

const LegalMetaContainer = styled('div')`
    grid-area: meta;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const LegalActionsContainer = styled('div')`
    grid-area: actions;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        padding-inline: 20px;
    }
`;

const LegalBodyContainer = styled('div')`
    display: grid;
    grid-template-columns: minmax(auto, 200px) 1fr minmax(auto, 200px);
    grid-template-rows: auto;
    gap: 16px;
    grid-template-areas: 'meta main-content actions';

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            'meta'
            'main-content'
            'actions';
    }
`;

const StickyContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: sticky;
    top: calc(16px + ${SUB_NAV_HEIGHT}px);
`;

const CoverImage = styled(GatsbyImage)`
    border-radius: 8px;
    border: 1px solid ${grey5};
    background: ${grey5};
    aspect-ratio: 4/3;
    will-change: transform;
    z-index: 0;
`;

const LegalTemplate = ({ data }) => {
    const { slug } = data.page;
    const { nodes: legals } = data.legals;

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.about.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <SwBreadcrumb basePage={'Company'} page={'Legal'} />
                    <SwContainer>
                        <Header>
                            <HeaderContent alignItems={'start'} flexDirection={'column'} gap={32}>
                                <SwTypography align={'left'} component={'h1'} variant={'h3'}>
                                    Legal
                                </SwTypography>
                                <SwTypography align={'left'} color={grey3} component={'p'} variant={'body1'}>
                                    The information provided here is for Sweep readers, subscribers and suppliers who
                                    have questions about our terms, policies, intellectual property, and compliance.
                                </SwTypography>
                            </HeaderContent>
                            <CoverImage
                                alt={data.page.coverImage.alt}
                                image={data.page.coverImage.gatsbyImageData}
                                objectFit={'cover'}
                            />
                        </Header>
                    </SwContainer>
                    <SwSubNav gap={32}>
                        {legals.map((legal) => (
                            <SwSubNav.Item
                                key={legal.id}
                                selected={legal.slug === slug}
                                state={{
                                    disableScrollUpdate: true
                                }}
                                to={`/${legal.slug}`}
                            >
                                {legal.title}
                            </SwSubNav.Item>
                        ))}
                    </SwSubNav>
                    <Box mt={4}>
                        <SwContainer paddingInline={{ xs: '0px' }}>
                            <LegalBodyContainer>
                                <LegalMetaContainer>
                                    <StickyContainer>
                                        <Paper>
                                            <SwTypography
                                                bold={true}
                                                color={grey3}
                                                component={'h2'}
                                                variant={'caption'}
                                            >
                                                Last Updated
                                            </SwTypography>
                                            <SwTypography variant={'body2'}>
                                                {' '}
                                                {dayjs(data.page.lastUpdated).format('MMMM YYYY')}
                                            </SwTypography>
                                        </Paper>
                                        <SwTableOfContents json={data.page.content.json} />
                                    </StickyContainer>
                                </LegalMetaContainer>
                                <MainContent>
                                    <Stack spacing={4}>
                                        <SwTypography component={'p'} variant={'headingStrong'}>
                                            {data.page.abstract}
                                        </SwTypography>
                                        <SwRichContent
                                            content={data.page.content.json}
                                            references={data.page.content.references}
                                        />
                                    </Stack>
                                </MainContent>
                                <LegalActionsContainer>
                                    <StickyContainer>
                                        <SwSharingLinksSection
                                            linkedIn={false}
                                            slug={`/${slug}`}
                                            title={data.page.title}
                                            twitter={false}
                                        />
                                    </StickyContainer>
                                </LegalActionsContainer>
                            </LegalBodyContainer>
                        </SwContainer>
                        <Box display={'flex'} flexDirection={'column'}>
                            <SwContainer>
                                <SwLine color={grey5} direction={'horizontal'} spacing={4} />
                            </SwContainer>
                            <SwContainer gap={32} paddingBlock={'16px'}>
                                <SwTypography bold={true} color={grey3} component={'h2'} variant={'body1'}>
                                    Latest Sweep updates
                                </SwTypography>
                            </SwContainer>
                            <SwContainer hasOverflowingContent={true}>
                                <SwLatestContent
                                    content={data.latestArticles.nodes}
                                    renderItems={(article) => (
                                        <SwRecordCard
                                            baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                            key={article.id}
                                            record={article}
                                            titleHeading={'h3'}
                                            width={{ xs: 300, md: 'auto' }}
                                        />
                                    )}
                                />
                            </SwContainer>
                        </Box>
                    </Box>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo }
    }
}) => (
    <Seo
        description={seo.description}
        image={seo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getPrivacyPageInformation($id: String) {
        legals: allGraphCmsLegal(filter: { locale: { eq: en } }) {
            nodes {
                id
                title
                slug
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: en } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        page: graphCmsLegal(id: { eq: $id }) {
            id
            remoteId
            slug
            title
            abstract
            lastUpdated
            coverImage {
                alt
                gatsbyImageData(aspectRatio: 1.334, layout: CONSTRAINED, width: 900, placeholder: BLURRED)
            }
            content {
                json
                references {
                    ...Essentials_GraphCMS_Asset
                    ...Essentials_GraphCMS_Quote
                    ...Essentials_GraphCMS_CallOut
                }
            }
            seo {
                description
                noIndex
                title
                image {
                    url
                }
            }
        }
    }
`;

export default LegalTemplate;
