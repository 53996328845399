import React, { useMemo } from 'react';
import { RichTextContent, EmptyElementsToRemove, ElementNode } from '@graphcms/rich-text-types';
import { styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { slugifyName } from '@lib/helpers/object';
import { SwCollapsibleSection } from './SwCollapsibleSection';
import { grey3 } from './bedrock/SwColors';
import SwTypography from './bedrock/typography/SwTypography';

export type TableOfContentsOptions = {
    depth: keyof typeof EmptyElementsToRemove;
    start: keyof typeof EmptyElementsToRemove;
};

export type SwTableOfContentsProps = Partial<TableOfContentsOptions> & {
    json: RichTextContent;
};

const depthMapping: Record<keyof typeof EmptyElementsToRemove, number> = {
    'heading-one': 1,
    'heading-two': 2,
    'heading-three': 3,
    'heading-four': 4,
    'heading-five': 5,
    'heading-six': 6,
    table_head: 7
};

const buildTableOfContents = (
    content: RichTextContent,
    option: Partial<TableOfContentsOptions> = { depth: 'heading-three', start: 'heading-two' }
) => {
    const byHeading = (node: ElementNode) =>
        depthMapping[node.type] &&
        depthMapping[node.type] <= depthMapping[option.depth] &&
        depthMapping[node.type] >= depthMapping[option.start];

    if (Array.isArray(content)) {
        return [...content].filter(byHeading);
    } else if (content.children && Array.isArray(content.children)) {
        return [...content.children].filter(byHeading);
    } else {
        return [];
    }
};

const LinksList = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(100vh - 400px - (5 * 16px));
    overflow-y: auto;
    width: 100%;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    font-size: 14px;
    color: black;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }

    &.sw-table-of-contents__link-heading-three {
        margin-inline-start: 8px;
        color: ${grey3};
    }
`;

export const SwTableOfContents: React.FC<SwTableOfContentsProps> = (props) => {
    const { json, depth = 'heading-three', start = 'heading-two' } = props;

    const tableOfContents = useMemo(() => buildTableOfContents(json, { depth, start }), [depth, start, json]);
    const { search } = useLocation();

    if (!tableOfContents.length) {
        return null;
    }

    return (
        <SwCollapsibleSection
            defaultExpanded={false}
            title={
                <SwTypography bold={true} color={grey3} component={'p'} variant={'caption'}>
                    Contents
                </SwTypography>
            }
        >
            <LinksList>
                {tableOfContents.map((content, index) => (
                    <li key={index}>
                        <StyledLink
                            className={`sw-table-of-contents__link-${content.type}`}
                            replace={false}
                            to={`${search}#${slugifyName(content.children[0]?.text)}`}
                        >
                            {content.children[0]?.text}
                        </StyledLink>
                    </li>
                ))}
            </LinksList>
        </SwCollapsibleSection>
    );
};
